import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth_reducer";
import whitelistReducer from "./lawyer_list/white_list_reducer";
import blackListReducer from "./lawyer_list/black_list_reducer";
import duesExemptReducer from "./lawyer_list/dues_exempt_reducer";
import deptDuesReducer from "./lawyer_list/dept_dues_reducer";
import manualGreenListReducer from "./lawyer_list/manual_green_list_reducer";
import barredListReducer from "./lawyer_list/barred_list_reducer";
import glcReducer from "./lawyer_list/glc_reducer";
import lawyerReducer from "./um/lawyer_reducer";
import pupilReducer from "./um/pupil_reducer";
import goodStandingReducer from "./um/good_standing_reducer";
import chamberReducer from "./chamber/chamber_reducer";
import conferenceReducer from "./conference/conference_reducer";
import duesReducer from "./payment/dues_reducer";
import licenseReducer from "./payment/license_reducer";
import chamberPaymentReducer from "./payment/chamber_reducer";
import pupilageReducer from "./payment/pupilage_reducer";
import stickerReducer from "./stickers/sticker_reducer";
import bootcampReducer from "./bootcamp/bootcamp_reducer";
import settingsReducer from "./misc/settings_reducer";
import trainingReducer from "./payment/training_reducer";
// import stickerReducer from "./payment/sticker_reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  whitelist: whitelistReducer,
  blacklist: blackListReducer,
  dues: duesExemptReducer,
  dept: deptDuesReducer,
  manual: manualGreenListReducer,
  barred: barredListReducer,
  glc: glcReducer,
  lawyer: lawyerReducer,
  pupil: pupilReducer,
  good: goodStandingReducer,
  chamber: chamberReducer,
  conference: conferenceReducer,
  duesPayment: duesReducer,
  license: licenseReducer,
  chamberPayment: chamberPaymentReducer,
  pupilage: pupilageReducer,
  sticker: stickerReducer,
  bootcamp: bootcampReducer,
  settings: settingsReducer,
  training: trainingReducer,
  // sticker: stickerReducer,
});

export default rootReducer;
