import {
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from "@material-tailwind/react";

import routes from "../routes/sidebar_routes";
import SideBarContent from "./SideBarContent";
import { PowerIcon } from "@heroicons/react/24/solid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/auth_actions";

function LeftSideBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <Card className="min-h-[calc(100vh - 0rem)] max-w-[20rem] p-4 shadow-xl shadow-blue-gray">
      <div className="mb-2 p-4">
        <Typography variant="h5" color="blue-gray">
          Dashboard
        </Typography>
      </div>

      {routes.map((section, index) => (
        <SideBarContent key={index} {...section} />
      ))}

      <div className="mt-24">
        <List>
          <ListItem onClick={handleLogout} className="color-red-50">
            <ListItemPrefix>
              <PowerIcon color="red" className="h-5 w-5" />
            </ListItemPrefix>
            <Typography color="red">Log Out</Typography>
          </ListItem>
        </List>
      </div>
    </Card>
  );
}

export default LeftSideBar;
