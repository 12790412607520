import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DetailButton from "../../../common/buttons/DetailButton";
import { allTrainingListAction } from "../../../redux/actions/payment/training_action";
import AlertMessage from "../../../common/actions/AlertMessage";
import Loader from "../../../common/loaders/Loader";
import PageTitle from "../../../common/shared/PageTitle";
import MoneyIcon from "../../../common/icons/moneyIcon";
import DataTable from "../../../common/shared/DataTable";

function Training() {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, message } = useSelector((state) => state.training);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "year", headerName: "Year", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <div>
          <DetailButton onClick={() => handleNavigate(params)} />
        </div>
      ),
    },
  ];

  const handleNavigate = (params) => {
    navigate(`/dashboard/training/details/${params.id}`);
  };

  useEffect(() => {
    dispatch(allTrainingListAction()).finally(() => setLoading(false));
  }, [dispatch]);

  return (
    <>
      {message && <AlertMessage type="error" message={message} />}
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-4">
            <div className="flex items-center">
              <PageTitle
                title="Training List"
                subheading=""
                icon={<MoneyIcon />}
              />
            </div>
          </div>

          <DataTable rows={data.product} columns={columns} />
        </>
      )}
    </>
  );
}

export default Training;
