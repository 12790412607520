import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { allTrainingListDetails } from "../../../redux/actions/payment/training_action";
import AlertMessage from "../../../common/actions/AlertMessage";
import Loader from "../../../common/loaders/Loader";
import PageTitle from "../../../common/shared/PageTitle";
import MoneyIcon from "../../../common/icons/moneyIcon";
import PaymentTable from "../../../common/shared/PaymentTable";

function SingleTraining() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const { data, message } = useSelector((state) => state.training);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "date_paid", headerName: "Date Paid", width: 150 },
    { field: "city", headerName: "City", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        const statusValue = params.row.status;
        return (
          <span
            className={`inline-flex items-center rounded-md ${
              statusValue === "Paid"
                ? "bg-green-50 text-green-700 ring-green-600/20"
                : "bg-orange-50 text-orange-700 ring-orange-600/20"
            } px-2 py-1 text-xs font-medium ring-1 ring-inset`}
          >
            {statusValue}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(allTrainingListDetails(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  return (
    <>
      {message && <AlertMessage type="error" message={message} />}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-4">
            <div className="flex items-center">
              <PageTitle
                title={data.product}
                subheading=""
                icon={<MoneyIcon />}
              />
            </div>
          </div>

          <PaymentTable data={data.details} columns={columns} />
        </>
      )}
    </>
  );
}

export default SingleTraining;
