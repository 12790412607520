import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMiscSettings,
  updateMiscSettings,
} from "../../redux/actions/misc/settings_action";
import AlertMessage from "../../common/actions/AlertMessage";
import Loader from "../../common/loaders/Loader";
import PageTitle from "../../common/shared/PageTitle";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import SettingsRadio from "../user_management/components/SettingsRadio";
import {
  Button,
  Card,
  CardBody,
  Spinner,
  Typography,
} from "@material-tailwind/react";

function SettingsPage() {
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);

  const [settingsData, setSettingsData] = useState({});
  const [settingsFormData, setSettingsFormData] = useState(null);

  const { data, dataError } = useSelector((state) => state.settings);

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.auth?.userData);

  const handleSettingChange = (settingType, field, newValue) => {
    const updateSetting = { ...settingsData };
    updateSetting[settingType][field] = newValue;
    setSettingsFormData(updateSetting);
  };

  const handleSaveSettings = () => {
    setUpdateLoading(true);

    dispatch(updateMiscSettings(settingsFormData)).finally(() =>
      setUpdateLoading(false)
    );
  };

  useEffect(() => {
    dispatch(getMiscSettings()).finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (data && !dataError) {
      setSettingsData(data.settings);
    }
  }, [data, dataError]);

  return (
    <>
      {dataError && (
        <AlertMessage type="error" message={dataError?.message || dataError} />
      )}

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between p-4">
            <div className="flex items-center">
              <PageTitle
                title="Lawyer Locator Settings"
                subheading=""
                icon={<Cog6ToothIcon />}
              />
            </div>
            <div>
              {userData.role_id === 3 && (
                <Button onClick={handleSaveSettings}>
                  {updateLoading ? <Spinner /> : "Save Settings"}
                </Button>
              )}
            </div>
          </div>

          {Object.keys(settingsData).length > 0 && (
            <div className="my-8 max-w-[1200px] m-auto px-4">
              <div className="grid sx:grid-cols-1 md:grid-cols-2 gap-20 my-4">
                <Card className=" ">
                  <CardBody>
                    <div className="flex justify-between">
                      <Typography variant="h4">Lawyers Info Access</Typography>
                    </div>
                    <SettingsRadio
                      title="Email Address"
                      settingType="lawyers"
                      field="email"
                      value={settingsData.lawyers.email}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Phone Number"
                      settingType="lawyers"
                      field="phone"
                      value={settingsData?.lawyers.phone}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Gender"
                      settingType="lawyers"
                      field="gender"
                      value={settingsData?.lawyers.gender}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Bar Number"
                      settingType="lawyers"
                      field="bar_number"
                      value={settingsData?.lawyers.bar_number}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Date of Birth"
                      settingType="lawyers"
                      field="date_of_birth"
                      value={settingsData?.lawyers.date_of_birth}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Date of Call"
                      settingType="lawyers"
                      field="enrolment_date"
                      value={settingsData?.lawyers.enrolment_date}
                      onChange={handleSettingChange}
                    />

                    <SettingsRadio
                      title="Academic Qualifications"
                      settingType="lawyers"
                      field="academic_q"
                      value={settingsData?.lawyers.academic_q}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Practice Areas"
                      settingType="lawyers"
                      field="practice_areas"
                      value={settingsData?.lawyers.practice_areas}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Practice Region"
                      settingType="lawyers"
                      field="location"
                      value={settingsData?.lawyers.location}
                      onChange={handleSettingChange}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className="flex justify-between">
                      <Typography variant="h4">Public Info Access</Typography>
                    </div>
                    <SettingsRadio
                      title="Email Address"
                      settingType="public"
                      field="email"
                      value={settingsData.public.email}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Phone Number"
                      settingType="public"
                      field="phone"
                      value={settingsData?.public.phone}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Gender"
                      settingType="public"
                      field="gender"
                      value={settingsData?.public.gender}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Bar Number"
                      settingType="public"
                      field="bar_number"
                      value={settingsData?.public.bar_number}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Date of Birth"
                      settingType="public"
                      field="date_of_birth"
                      value={settingsData?.public.date_of_birth}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Date of Call"
                      settingType="public"
                      field="enrolment_date"
                      value={settingsData?.public.enrolment_date}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Academic Qualifications"
                      settingType="public"
                      field="academic_q"
                      value={settingsData?.public.academic_q}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Practice Areas"
                      settingType="public"
                      field="practice_areas"
                      value={settingsData?.public.practice_areas}
                      onChange={handleSettingChange}
                    />
                    <SettingsRadio
                      title="Practice Region"
                      settingType="public"
                      field="location"
                      value={settingsData?.public.location}
                      onChange={handleSettingChange}
                    />
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className="flex justify-between">
                      <Typography variant="h4">GLC</Typography>
                    </div>
                    <SettingsRadio
                      title="Email Address"
                      settingType="GLC"
                      field="email"
                      value={settingsData.GLC.email}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Phone Number"
                      settingType="GLC"
                      field="phone"
                      value={settingsData?.GLC.phone}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Gender"
                      settingType="GLC"
                      field="gender"
                      value={settingsData?.GLC.gender}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Bar Number"
                      settingType="GLC"
                      field="bar_number"
                      value={settingsData?.GLC.bar_number}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Date of Birth"
                      settingType="GLC"
                      field="date_of_birth"
                      value={settingsData?.GLC.date_of_birth}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Date of Call"
                      settingType="GLC"
                      field="enrolment_date"
                      value={settingsData?.GLC.enrolment_date}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Academic Qualifications"
                      settingType="GLC"
                      field="academic_q"
                      value={settingsData?.GLC.academic_q}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Practice Areas"
                      settingType="GLC"
                      field="practice_areas"
                      value={settingsData?.GLC.practice_areas}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                    <SettingsRadio
                      title="Practice Region"
                      settingType="GLC"
                      field="location"
                      value={settingsData?.GLC.location}
                      onChange={handleSettingChange}
                      disable={true}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SettingsPage;
