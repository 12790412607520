import React from "react";
import { Card, CardBody } from "@material-tailwind/react";
import { DataGrid } from "@mui/x-data-grid";

function PaymentTable({ data, columns }) {
  return (
    <Card className="w-full p-6 shadow-xl mb-20">
      <CardBody>
        <DataGrid
          sx={{ border: 0 }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                date_paid: false,
              },
            },
          }}
          rows={data
            .filter((row) => row.status === 1 || row.status === 0)
            .map((row, index) => ({
              ...row,
              arrayIndex: index,
              status:
                row.status === 1 ? "Paid" : row.status === 0 ? "Pending" : null,
            }))}
          columns={columns}
        />
      </CardBody>
    </Card>
  );
}

export default PaymentTable;
