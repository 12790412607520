import { message } from "antd";
import * as types from "../../constants/payment/training_constants";

const initialState = {
  data: null,
  details: null,
  message: null,
};

const trainingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.TRAINING_LIST_SUCCESS:
    case types.TRAINING_LIST_DETAILS_SUCCESS:
      return { ...state, data: payload ? payload : null };

    case types.TRAINING_LIST_DETAILS_FAILURE:
    case types.TRAINING_LIST_FAILURE:
      return { ...state, message: payload ? payload : null };

    default:
      return state;
  }
};

export default trainingReducer;
