import createAsyncAction from "../../../utils/async_action_utils";
import * as api from "../../api/misc/settings_api";
import * as types from "../../constants/misc/settings_constant";

const getMiscSettingsTypes = {
  success: types.GET_MISC_SETTINGS_SUCCESS,
  failure: types.GET_MISC_SETTINGS_FAILURE,
};

const miscSettingUpdateTypes = {
  success: types.UPDATE_MISC_SETTINGS_SUCCESS,
  failure: types.UPDATE_MISC_SETTINGS_FAILURE,
};

const aiTrainingTypes = {
  success: types.AI_TRAINING_SUCCESS,
  failure: types.AI_TRAINING_FAILURE,
};

export const getMiscSettings = createAsyncAction(
  api.getMiscSettings,
  getMiscSettingsTypes
);

export const updateMiscSettings = createAsyncAction(
  api.updateMiscSettings,
  miscSettingUpdateTypes
);

export const aiTrainingAction = createAsyncAction(
  api.aiTraining,
  aiTrainingTypes
);
