const MoneyIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    width={30}
    height={30}
    viewBox="0 0 64 64"
    {...props}
  >
    <style>
      {
        ".st4{fill:#8e8eb2}.st5,.st6{fill:none;stroke:#000;stroke-miterlimit:10}.st6{opacity:.5;stroke:#fff;strokeWidth:2;strokeLinecap:round;enable-background:new}"
      }
    </style>
    <path
      d="m43 23.9-7.5 7.5c-.2.2-.4.4-.7.5-1.2.7-2.7.5-3.7-.5l-7.5-7.5c-1.2-1.2-1.2-3.1 0-4.3l7.5-7.5c.7-.5 1.5-.8 2.3-.8.5 0 1 .1 1.5.4.2.1.5.3.7.5l7.5 7.5c1.2 1.1 1.2 3.1-.1 4.2z"
      style={{
        fill: "#12af76",
      }}
    />
    <path
      d="M34.8 11.8c-.4-.2-.8-.3-1.4-.3h-.2l1.3 1.3 6.6 6.6h1.3l-7.1-7.1c-.1-.3-.3-.4-.5-.5z"
      style={{
        fill: "#096",
      }}
    />
    <path d="M33.5 32.8c-.9 0-1.8-.4-2.5-1l-7.5-7.5c-1.4-1.4-1.4-3.6 0-5l7.5-7.5c1.1-1.1 2.9-1.3 4.2-.6.3.2.5.3.8.6l7.5 7.5c1.4 1.4 1.4 3.6 0 5L36 31.8c-.2.2-.5.4-.8.6-.5.2-1.2.4-1.7.4zm0-21.1c-.7 0-1.3.3-1.8.8L24.2 20c-1 1-1 2.6 0 3.6l7.5 7.5c.8.8 2.1 1 3.1.4.2-.1.4-.3.6-.4l7.5-7.5c1-1 1-2.6 0-3.6l-7.5-7.5c-.2-.2-.4-.3-.6-.4-.4-.3-.9-.4-1.3-.4z" />
    <path
      d="M38.3 19.3c0-.1.1-.1.1-.2L36 16.8c-1.5 1.5-3.9 1.5-5.3 0l-2.3 2.3s.1.1.1.2h9.8z"
      style={{
        opacity: 0.5,
        fill: "#fff",
        enableBackground: "new",
      }}
    />
    <path d="M31 17.3c1.4 1.1 3.6 1.1 5 0l2 2h1.3l-2.9-2.9c-.2-.2-.5-.2-.7 0-1.2 1.2-3.2 1.2-4.4 0-.2-.2-.5-.2-.7 0l-2.9 2.9H29l2-2z" />
    <path
      d="M51 52.8H15c-1.7 0-3-1.3-3-3v-27c0-1.7 1.3-3 3-3h36c1.7 0 3 1.3 3 3v27c0 1.7-1.3 3-3 3z"
      style={{
        fill: "#b2b1cf",
        stroke: "#000",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M54 49.8v-27c0-1.7-1.3-3-3-3h-2c1.7 0 3 1.3 3 3v27c0 1.7-1.3 3-3 3h2c1.7 0 3-1.3 3-3zM48 43.8v-15c0-1.7-1.3-3-3-3h-2c1.7 0 3 1.3 3 3v15c0 1.7-1.3 3-3 3h2c1.7 0 3-1.3 3-3z"
      className="st4"
    />
    <path
      d="M51 52.8H15c-1.7 0-3-1.3-3-3v-27c0-1.7 1.3-3 3-3h36c1.7 0 3 1.3 3 3v27c0 1.7-1.3 3-3 3z"
      className="st5"
    />
    <path d="M14.5 30.3v-6c0-1.1.9-2 2-2M14.5 48.3v-6" className="st6" />
    <path
      d="M21 46.8c-1.7 0-3-1.3-3-3v-15c0-1.7 1.3-3 3-3h24c1.7 0 3 1.3 3 3v15c0 1.7-1.3 3-3 3H21z"
      className="st5"
    />
    <path
      d="M45.5 42.3c0 1.1-.9 2-2 2M20.5 30.3c0-1.1.9-2 2-2"
      className="st6"
    />
    <path
      d="M10.8 39.8c-.4 0-.8-.2-.8-.9v-5.3c0-.5.3-.8.8-.8h12.7c1.3 0 2.4 1.1 2.4 2.4v2.2c0 1.3-1.1 2.4-2.4 2.4H10.8z"
      style={{
        fill: "#edc620",
        stroke: "#000",
        strokeMiterlimit: 10,
      }}
    />
    <circle cx={22.5} cy={36.3} r={1.5} className="st5" />
    <path d="M51.5 48.3c0 1.1-.9 2-2 2" className="st6" />
  </svg>
);
export default MoneyIcon;
