import createAsyncAction from "../../../utils/async_action_utils";
import * as api from "../../api/payment/training_api";
import * as types from "../../constants/payment/training_constants";

const getAllTrainingListTypes = {
  success: types.TRAINING_LIST_SUCCESS,
  failure: types.TRAINING_LIST_FAILURE,
};

const getTrainingListDetailsTypes = {
  success: types.TRAINING_LIST_DETAILS_SUCCESS,
  failure: types.TRAINING_LIST_DETAILS_FAILURE,
};

export const allTrainingListAction = createAsyncAction(
  api.allTrainingList,
  getAllTrainingListTypes
);

export const allTrainingListDetails = createAsyncAction(
  api.trainingDetails,
  getTrainingListDetailsTypes
);
