import { Typography } from "@material-tailwind/react";
import { Radio } from "antd";
import React from "react";

function SettingsRadio({
  title,
  disable = false,
  settingType,
  field,
  value,
  onChange,
}) {
  const handleChange = (e) => {
    onChange(settingType, field, e.target.value);
  };

  return (
    <div className="flex items-center justify-between my-2">
      <Typography variant="paragraph">{title}</Typography>

      <Radio.Group value={value} onChange={handleChange}>
        <Radio value={true} disabled={disable}>
          Show
        </Radio>
        <Radio value={false} disabled={disable}>
          Hide
        </Radio>
      </Radio.Group>
    </div>
  );
}

export default SettingsRadio;
