import { handleApi } from "..";
import {
  AI_TRAINING,
  MISC_SETTINGS,
  MISC_SETTINGS_UPDATE,
} from "../../constants/api_constants";

export const getMiscSettings = async () => {
  return handleApi("get", MISC_SETTINGS);
};

export const updateMiscSettings = async (values) => {
  return handleApi("put", MISC_SETTINGS_UPDATE, values);
};

export const aiTraining = async () => {
  return handleApi("get", AI_TRAINING);
};
