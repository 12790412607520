import { handleApi } from "..";
import {
  TRAINING_LIST,
  TRAINING_LIST_DETAILS,
} from "../../constants/api_constants";

export const allTrainingList = async () => {
  return handleApi("get", TRAINING_LIST);
};

export const trainingDetails = async (id) => {
  return handleApi("get", `${TRAINING_LIST_DETAILS}/${id}`);
};
